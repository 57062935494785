<template>
<div class="commercialTenant">
  <el-form :inline="true">
    <el-form-item>
      <myButton title="新增" @myclick="dialogVisible = true">
        <template slot="image">
          <img src="../../assets/image/btnicon/add.png" alt="">
        </template>
      </myButton>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" :cell-style="{'text-align':'center'}" :header-cell-style="{'text-align':'center','background':'#F5F5F5'}">
    <el-table-column label="市场编号" prop="mktId"></el-table-column>
    <el-table-column label="市场名称" prop="mktName"></el-table-column>
    <el-table-column label="默认商户" prop="mktMrchId"></el-table-column>
    <el-table-column label="项目编号" prop="prjId"></el-table-column>
    <el-table-column label="分账规则编号" prop="clrgRuleId"></el-table-column>
    <el-table-column label="商户数量" prop="merchantTotal"></el-table-column>
    <el-table-column label="添加时间" prop="createAt"></el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <div class="slot-box">
          <pop tips="商户列表" @myclick="$router.push({path: `/bankingManagement/merchantList?id=${scope.row.id}`})">
            <img class="icon" src="../../assets/image/icon/list.png" alt="">
          </pop>
          <pop tips="编辑" @myclick="edit(scope.row)">
            <img class="icon" src="../../assets/image/icon/edit.png" alt="">
          </pop>
          <pop tips="删除" @myclick="del(scope.row)">
            <img class="icon" src="../../assets/image/icon/sc.png" />
          </pop>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="publicPage">
    <el-pagination next-text="下一页"  prev-text="上一页" layout="total, prev, pager, next" :total="total" :page-size="form.pageSize" :current-page="form.page" @current-change="currentChange"></el-pagination>
  </div>
  <el-dialog title="新增/编辑" :visible.sync="dialogVisible" width="880px" @close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="市场名称" prop="mktName">
        <el-input v-model="ruleForm.mktName" class="public-input" placeholder="请输入市场名称"></el-input>
      </el-form-item>
      <el-form-item label="市场编号" prop="mktId">
        <el-input v-model="ruleForm.mktId" class="public-input" placeholder="请输入市场编号"></el-input>
      </el-form-item>
      <el-form-item label="默认商户" prop="mktMrchId">
        <el-input v-model="ruleForm.mktMrchId" class="public-input" placeholder="请输入默认商户"></el-input>
      </el-form-item>
      <el-form-item label="支付渠道代码" prop="chnlCd">
        <el-input v-model="ruleForm.chnlCd" class="public-input" placeholder="请输入支付渠道代码"></el-input>
      </el-form-item>
      <el-form-item label="项目编号" prop="prjId">
        <el-input v-model="ruleForm.prjId" class="public-input" placeholder="请输入项目编号"></el-input>
      </el-form-item>
      <el-form-item label="发起渠道编号" prop="ittpartyStmId">
        <el-input v-model="ruleForm.ittpartyStmId" class="public-input" placeholder="请输入发起渠道编号"></el-input>
      </el-form-item>
      <el-form-item label="私钥" prop="mktPrivateKey">
        <el-input v-model="ruleForm.mktPrivateKey" class="public-input" placeholder="请输入私钥"></el-input>
      </el-form-item>
      <el-form-item label="公钥" prop="mktPublicKey">
        <el-input v-model="ruleForm.mktPublicKey" class="public-input" placeholder="请输入公钥"></el-input>
      </el-form-item>
      <el-form-item label="银行公钥" prop="hsbPublicKey">
        <el-input v-model="ruleForm.hsbPublicKey" class="public-input" placeholder="请输入银行公钥"></el-input>
      </el-form-item>
      <el-form-item label="分账规则编号" prop="clrgRuleId">
        <el-input v-model="ruleForm.clrgRuleId" class="public-input" placeholder="请输入分账规则编号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <myButton @myclick="dialogVisible = false" right>取消</myButton>
      <myButton @myclick="confirm">确定</myButton>
    </span>
  </el-dialog>
</div>
</template>
<script>
export default {
  name: 'list',
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      ruleForm: {
        mktName: '',
        mktId: '',
        chnlCd: '',
        ittpartyStmId: '',
        mktPrivateKey: '',
        mktMrchId: '',
        mktPublicKey: '',
        hsbPublicKey: '',
        clrgRuleId: '',
        prjId: ''
      },
      rules: {
        mktName: [
          { required: true, message: '请输入市场名称', trigger: 'blur' }
        ],
        mktId: [
          { required: true, message: '请输入市场编号', trigger: 'blur' }
        ],
        chnlCd: [
          { required: true, message: '请输入支付渠道代码', trigger: 'blur' }
        ],
        ittpartyStmId: [
          { required: true, message: '请输入发起渠道编号', trigger: 'blur' }
        ],
        mktPrivateKey: [
          { required: true, message: '请输入私钥', trigger: 'blur' }
        ],
        mktMrchId: [
          { required: true, message: '请输入默认商户', trigger: 'blur' }
        ],
        mktPublicKey: [
          { required: true, message: '请输入公钥', trigger: 'blur' }
        ],
        hsbPublicKey: [
          { required: true, message: '请输入银行公钥', trigger: 'blur' }
        ],
        clrgRuleId: [
          { required: true, message: '请输入分账规则编号', trigger: 'blur' }
        ],
        prjId: [
          { required: true, message: '请输入项目编号', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    //do something after creating vue instance
    this.getList();
  },
  methods: {
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$request.HttpPost('/admin/hsbMktInfo/deleteByIds', {
          ids: [row.id]
        }).then(res => {
          this.$plugin.notifySuccess(res.msg);
          this.getList();
        });
      });
    },
    edit(row) {
      let str = row;
      this.ruleForm = { ...str };
      this.dialogVisible = true;
    },
    getList() {
      this.$request.HttpGet('/admin/hsbMktInfo/list', this.form).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    currentChange(page) {
      this.form.page = page;
      this.getList();
    },
    handleClose() {
      this.ruleForm = {
        mktName: '',
        mktId: '',
        chnlCd: '',
        ittpartyStmId: '',
        mktPrivateKey: '',
        mktMrchId: '',
        mktPublicKey: '',
        hsbPublicKey: '',
        clrgRuleId: '',
        prjId: ''
      };
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let url = '';
          if (this.ruleForm.id) {
            url = '/admin/hsbMktInfo/update';
          } else {
            url = '/admin/hsbMktInfo/create';
          }
          this.$request.HttpPost(url, this.ruleForm).then(res => {
            this.$plugin.notifySuccess(res.msg);
            this.getList();
            this.dialogVisible = false;
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
